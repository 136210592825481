// eslint-disable-next-line import/prefer-default-export
export const UUID_REGEX = new RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}', 'i');

// SNAKE-CAMEL-CONVERSION
export function strToCamel(str) {
  return str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase().replace('-', '').replace('_', ''),
  );
}

export function objToCamel(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [strToCamel(k), v]));
}

export function getBotAdminEnvironment() {
  // Be cautious of what you allow yourself to use this value for. An end-user
  // (although a technical one) may change the value him/herself in the developer console.
  // In any case you should not rely on information based in the frontend to allow/disallow making
  // changes in the backend; the backend should always itself verify that the user is allowed to
  // perform the requested operation.
  return process.env.NODE_ENV !== 'production' ? process.env.NODE_ENV : window.botadminEnvironment;
}

export function getBotAdminName() {
  return process.env.NODE_ENV !== 'production' ? process.env.ENABLE_BOTADMIN_NAME_FEATURE : window.botadminName;
}
