import '@/public-path';
import VueLogger from 'vuejs3-logger';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from '@/router';
import store from '@/store';
import app from '@/app';
import SupWizPrimeVue from 'supwiz/primevue/plugin';

// add tailwind css
import '@/assets/tailwind.css';

import '@/js/icons';

const isProduction = process.env.NODE_ENV === 'production';

// Add components, utilities, and more
app.use(SupWizPrimeVue);
app.component('FontAwesomeIcon', FontAwesomeIcon);
app.use(VueLogger, {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
});
app.use(store);
app.use(router);

// finally, mount app
app.mount('#app');

if (!isProduction) {
  const consoleErrorOrg = console.error;
  // eslint-disable-next-line no-inner-declarations
  function consoleErrorMock() {
    // eslint-disable-next-line prefer-rest-params
    consoleErrorOrg(...arguments);
    if (app) {
      app.config.globalProperties.$toast.add({
        summary: '[SupwizDEV]: An error occurred in console',
        detail: 'Console has errors',
        severity: 'error',
        life: 2000,
      });
    }
  }
  console.error = consoleErrorMock;
}
