<template>
  <div
    class="flex items-center justify-center-center
      inset-0 absolute transition-opacity"
    :style="{ opacity }"
  >
    <PvProgressSpinner
      class="h-20 w-20"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      opacity: 0,
    };
  },
  created() {
    this.opacity = 1;
  },
};
</script>
