<template>
  <div class="container">
    <div>
      <SwRow class="content-center items-center pt-5 text-center" align-h="center">
        <SwCol cols="8">
          <PvCard>
            <template #title>
              Insufficient permissions to view the requested page.
            </template>
            <template #content>
              <p class="mt-4">
                Given your user-permissions you're not allowed to view the requested page. <br>
                You should contact your administrator.
              </p>
            </template>
          </PvCard>
        </SwCol>
      </SwRow>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotAllowedToViewPage',
};
</script>
