import botBackend from '@/backend/botManipulationBackend';

const initialState = {
  botsCurrentState: [],
  botsCurrentStateTimestamp: null,
  workerStateId: '',
  workerStateText: '',
  eventLog: [],
  stableDeployment: true,
  impossibleDeployment: false,
  delayedDeployment: false,
  isWorkersUpdating: false,
  adaptations: {},
  maxWorkersUpdating: 0,
  fillFreeWith: 'Unchanged',
  knownLabels: [],
};

const getters = {
  botsCurrentState: (state) => state.botsCurrentState,
  botsCurrentStateTimestamp: (state) => state.botsCurrentStateTimestamp,
  eventLog: (state) => state.eventLog,
  workerStateId: (state) => state.workerStateId,
  workerStateText: (state) => state.workerStateText,
  isWorkersUpdating: (state) => state.isWorkersUpdating,
  isDeploying: (state) => !state.stableDeployment,
  isDeployingDelayed: (state) => state.delayedDeployment,
  isImpossibleDeployment: (state) => state.impossibleDeployment,
  adaptations: (state) => state.adaptations,
  maxWorkersUpdating: (state) => state.maxWorkersUpdating,
  fillFreeWith: (state) => state.fillFreeWith,
  knownLabels: (state) => state.knownLabels,
};

const mutations = {
  setAdaptations(state, payload) {
    state.adaptations = payload;
  },
  setMaxWorkersUpdating(state, payload) {
    state.maxWorkersUpdating = payload;
  },
  setImpossibleDeployment(state, enabled) {
    state.impossibleDeployment = !!enabled;
  },
  setStableDeployment(state, enabled) {
    state.stableDeployment = !!enabled;
  },
  setDelayedDeployment(state, enabled) {
    state.delayedDeployment = enabled;
  },
  setWorkersUpdating(state, enabled) {
    state.isWorkersUpdating = !!enabled;
  },
  setFillFreeWith(state, payload) {
    state.fillFreeWith = payload;
  },
  setKnownLabels(state, labels) {
    state.knownLabels = labels || [];
  },
  updateWorkerStateText(state, { workerId, text }) {
    if (state.workerStateId === workerId) {
      state.workerStateText = text;
    }
  },
  updateWorkerStateId(state, { workerId }) {
    if (state.workerStateId !== workerId) {
      state.workerStateId = workerId;
      state.workerStateText = 'Pending...';
    }
  },
  updateBotStatus(state, newEntries) {
    state.botsCurrentState = newEntries;
    state.botsCurrentStateTimestamp = new Date();
  },
  addEventLog(state, newEntry) {
    state.eventLog.push(newEntry);
  },
  addEventLogWeak(state, newEntry) {
    // If we reconnect, we may get the same errors again
    const eventId = newEntry.id || 'not_an_uuid';
    for (const oldEvent of state.eventLog) {
      if (oldEvent.id === eventId) return;
    }
    state.eventLog.push(newEntry);
  },
  clearEventLog(state) {
    state.eventLog = [];
  },
};

const actions = {
  async deployBot(state, payload) {
    await botBackend.deployBot({ payload: { ...payload } });
  },
  async activateBots(state, botsToActivate) {
    await botBackend.makeBotActive(botsToActivate);
  },
  async deactivateBots(state, botsToActivate) {
    await botBackend.makeBotInactive(botsToActivate);
  },
  async requestWorkerState(state, workerId) {
    await botBackend.requestWorkerState(workerId);
  },
  async workerCommand(state, { workerIds, command, commandArg }) {
    await botBackend.sendWorkerCommand(workerIds, command, commandArg);
  },
  async killWorkerPod({ dispatch }, payload) {
    const commandPayload = { ...payload, command: 'kill', commandArg: '' };
    await dispatch('workerCommand', commandPayload);
  },
  async resetWorker({ dispatch }, payload) {
    const commandPayload = { ...payload, command: 'reset', commandArg: '' };
    await dispatch('workerCommand', commandPayload);
  },
  async oomKillWorker({ dispatch }, payload) {
    const commandPayload = { ...payload, command: 'oom', commandArg: '' };
    await dispatch('workerCommand', commandPayload);
  },
  async resetWorkerBotId({ dispatch }, payload) {
    const commandPayload = { ...payload, command: 'reset-bot-id', commandArg: '' };
    await dispatch('workerCommand', commandPayload);
  },
  updateBotStatus({ commit }, replacingEntries) {
    commit('updateBotStatus', replacingEntries);
  },
  addEventLog({ commit }, newEntry) {
    commit('addEventLog', newEntry);
  },
  addEventLogWeak({ commit }, newEntry) {
    commit('addEventLogWeak', newEntry);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
