import botBackend from '@/backend/botManipulationBackend';
import { deepEqualsJson } from 'supwiz/util/data';

const initialState = {
  graph: {},
  lastUpdatedTimestamp: 0,
};

const getters = {
  graph: (state) => state.graph,
  getLastUpdatedTimestamp: (state) => state.lastUpdatedTimestamp,
};

const mutations = {
  setGraph(state, { newState }) {
    if (!deepEqualsJson(newState, state.graph)) {
      state.lastUpdatedTimestamp = Date.now();
      state.graph = newState;
    }
  },
};

const actions = {
  async sendExternalGraph({ dispatch }, { graph }) {
    // Wait for control to confirm them
    try {
      await botBackend.sendExternalSettings({ graph });
    } catch (error) {
      dispatch('templateStore/templateSendNotification', {
        title: 'Failed to update graph settings',
        text: error.response.data.error,
        variant: 'danger',
        toast: true,
      }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
