<template>
  <div class="flex items-center w-full h-14">
    <div class="pr-3 py-1 flex items-center flex-auto">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
};
</script>
