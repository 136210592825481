// SupWiz preset aligned with modified PrimeVue styles
// Note: Tailwind default colors are removed and cannot be used
// Only colors defined here can be used. This is to ensure consistent use of colors
// see all base colors in supwiz/primevue/themes/aura/aura-light/_variables.scss
export default {
  corePlugins: {
    // preflight is a set of reset styles which primevue also has
    // so we disable it to avoid conflicts
    preflight: false,
  },
  theme: {
    extend: {
      animation: {
        'debounce-pulse': 'debounce-pulse 1s ease-in-out infinite',
      },
      keyframes: {
        'debounce-pulse': {
          '0%': { 'box-shadow': '0 0 0 0rem rgba(0, 0, 0, 0.3)' },
          '100%': { 'box-shadow': '0 0 0 .6rem rgba(0, 0, 0, 0)' },
        },
      },
    },
    borderRadius: {
      // use border radius from primevue
      none: 'var(--p-border-radius-none)',
      xs: 'var(--p-border-radius-xs)',
      sm: 'var(--p-border-radius-sm)',
      DEFAULT: 'var(--p-border-radius-md)',
      md: 'var(--p-border-radius-md)',
      lg: 'var(--p-border-radius-lg)',
      xl: 'var(--p-border-radius-xl)',
      full: '9999px',
    },
    fontWeight: {
      light: '400',
      normal: '500',
      semibold: '600',
      bold: '700',
    },
    colors: {
      blue: {
        DEFAULT: '#4E64CA',
        50: '#DBE0F4',
        100: '#CCD2F0',
        200: '#ACB6E6',
        300: '#8D9BDD',
        400: '#6D7FD3',
        500: '#4E64CA',
        600: '#3449AC',
        700: '#273781',
        800: '#1A2456',
        900: '#0D122B',
        950: '#060915',
      },
      green: {
        DEFAULT: '#46822B',
        50: '#DBF0D1',
        100: '#C8E8BA',
        200: '#9BD581',
        300: '#76C653',
        400: '#5FB03A',
        500: '#46822B',
        600: '#2F581D',
        700: '#192E0F',
        800: '#15260D',
        900: '#111F0A',
        950: '#0A1306',
      },
      yellow: {
        DEFAULT: '#E2A122',
        50: '#F7E6C4',
        100: '#F5DEB2',
        200: '#F0CF8E',
        300: '#EBC06A',
        400: '#E7B046',
        500: '#E2A122',
        600: '#B47F18',
        700: '#835C11',
        800: '#51390B',
        900: '#1F1604',
        950: '#070501',
      },
      cyan: {
        50: '#ecfcff',
        100: '#cff7fe',
        200: '#a5effc',
        300: '#67e4f9',
        400: '#22d0ee',
        500: '#06b6d4',
        DEFAULT: '#06b6d4',
        600: '#0899b2',
        700: '#0e7d90',
        800: '#156775',
        900: '#165863',
        950: '#083b44',
      },
      pink: {
        50: '#fdf2f7',
        100: '#fce7f1',
        200: '#fbcfe5',
        300: '#f9a8d0',
        400: '#f472b2',
        500: '#ec4899',
        DEFAULT: '#ec4899',
        600: '#db2780',
        700: '#be186a',
        800: '#9d1759',
        900: '#83184d',
        950: '#50072b',
      },
      indigo: {
        50: '#eeeeff',
        100: '#e0e1ff',
        200: '#c7c8fe',
        300: '#a5a7fc',
        400: '#8184f8',
        500: '#6366f1',
        DEFAULT: '#6366f1',
        600: '#4649e5',
        700: '#383bca',
        800: '#3032a3',
        900: '#2e3081',
        950: '#1b1c4b',
      },
      teal: {
        50: '#f0fdfc',
        100: '#ccfbf6',
        200: '#99f6ec',
        300: '#5eeadb',
        400: '#2dd4c2',
        500: '#14b8a6',
        DEFAULT: '#14b8a6',
        600: '#0d9485',
        700: '#0f766b',
        800: '#115e56',
        900: '#134e48',
        950: '#042f2a',
      },
      orange: {
        50: '#fff4ed',
        100: '#ffe6d5',
        200: '#feccaa',
        300: '#fdac74',
        400: '#fb8a3c',
        500: '#f97316',
        DEFAULT: '#f97316',
        600: '#ea670c',
        700: '#c2570c',
        800: '#9a4a12',
        900: '#7c3d12',
        950: '#432007',
      },
      gray: {
        DEFAULT: '#515665',
        50: '#DCDEE3',
        100: '#D1D3DA',
        200: '#BABEC8',
        300: '#A4A8B6',
        400: '#8D93A3',
        500: '#767D91',
        600: '#63697C',
        700: '#515665',
        800: '#32424F',
        900: '#1F2127',
        950: '#131417',
      },
      purple: {
        50: '#faf5ff',
        100: '#f4e8ff',
        200: '#ebd5ff',
        300: '#dab4fe',
        400: '#c184fc',
        500: '#a855f7',
        DEFAULT: '#a855f7',
        600: '#9133ea',
        700: '#7a22ce',
        800: '#6621a8',
        900: '#531c87',
        950: '#370764',
      },
      primary: {
        DEFAULT: '#EB1562',
        50: '#FBD0DF',
        100: '#F9BDD3',
        200: '#F7A1C0',
        300: '#F377A4',
        400: '#EE3B7B',
        500: '#EB1562',
        600: '#B8104C',
        700: '#840B37',
        800: '#510721',
        900: '#1D020C',
        950: '#030001',
      },
      red: {
        DEFAULT: '#DE1D1A',
        50: '#FBDBDA',
        100: '#F9C9C8',
        200: '#F5AEAD',
        300: '#F0817F',
        400: '#E94644',
        500: '#DE1D1A',
        600: '#AC1614',
        700: '#7A100E',
        800: '#520B0A',
        900: '#2E0605',
        950: '#1B0403',
      },
      black: '#000',
      white: '#fff',
      transparent: 'transparent',
      shade: {
        DEFAULT: '#EEEEEE',
        50: '#EEEEEE',
        100: '#E7E7E7',
        200: '#C8C8C8',
        300: '#B4B4B4',
        400: '#9F9F9F',
        500: '#8B8B8B',
        600: '#6F6F6F',
        700: '#535353',
        800: '#373737',
        900: '#1B1B1B',
        950: '#0D0D0D',
      },
    },
    fontFamily: {
      sans: 'var(--sw-font-sans)',
      mono: 'var(--sw-font-mono)',
    },
  },
};
