<template>
  <div>
    <PvButtonGroup class="flex items-start">
      <template v-if="split">
        <PvButton
          :class="{
            'h-8 text-sm': size === 'small',
            'h-10': size === 'medium',
            'h-12 text-lg': size === 'large',
          }"
          v-bind="$attrs"
          :severity="severity"
          @click="$emit('click')"
        >
          {{ label }}
          <slot name="button-content" />
        </PvButton>
        <PvButton
          :class="{
            'h-8 text-sm': size === 'small',
            'h-10': size === 'medium',
            'h-12 text-lg': size === 'large',
          }"
          v-bind="$attrs"
          :severity="severity"
          @click="e => togglePanel(e, id)"
        >
          <FontAwesomeIcon icon="caret-down" />
        </PvButton>
      </template>
      <PvButton
        v-else
        v-bind="$attrs"
        :class="{
          'h-8 text-sm': size === 'small',
          'h-10': size === 'medium',
          'h-12 text-lg': size === 'large',
        }"
        :severity="severity"
        @click="e => togglePanel(e, id)"
      >
        {{ label }}
        <slot name="button-content" />
      </PvButton>
    </PvButtonGroup>
    <PvPopover
      :ref="id"
      :pt="{ content: contentClass }"
      @show="$emit('show')"
      @hide="$emit('hide')"
    >
      <slot name="content" />
    </PvPopover>
  </div>
</template>
<script>
export default {
  name: 'Dropdown',
  props: {
    id: {
      type: String,
      default: 'dropdown',
    },
    label: {
      type: String,
      default: '',
    },
    contentClass: {
      type: Object,
      default: () => ({}),
    },
    split: {
      default: false,
      type: Boolean,
    },
    severity: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  emits: ['click', 'show', 'hide'],
  methods: {
    togglePanel(event, ref) {
      this.$refs[ref].toggle(event);
    },
  },
};
</script>
