export default {
  breadcrumbs: {
    home: 'Home',
  },
  notifications: {
    seeNotifications: 'Click to see current notifications',
    noNotifications: 'No notifications',
    clearNotification: 'Remove',
    clearNotifications: 'Clear Notifications',
  },
  sidebar: {
    enableCompactSidebar: 'Enable Compact Sidebar',
    disableCompactSidebar: 'Disable Compact Sidebar',
    search: {
      clearSearch: 'Clear Search',
      noResults: 'No Results',
      searchPages: 'Search Pages',
    },
  },
  userMenu: {
    buttonTitle: 'Click to perform user options like signing out',
    greeting: 'Hi',
    logOut: 'Log out',
    noName: 'Missing name',
  },
};
