<template>
  <div
    :class="[
      'grid transition-all duration-300 ease-in-out',
      {
        'grid-rows-[0fr]': collapsed,
        'grid-rows-[1fr]': !collapsed,
      },
    ]"
    role="tabpanel"
  >
    <div class="overflow-hidden">
      <div
        :class="[
          'relative transition-all duration-300 ease-in-out',
          contentClass,
          {
            '-translate-y-10 opacity-0': collapsed,
            'translate-y-0 opacity-100': !collapsed,
          },
        ]"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collapsible',
  props: {
    collapsed: {
      type: Boolean,
      required: false,
    },
    contentClass: {
      type: String,
      default: '',
    },
  },
};
</script>
