import axios from 'axios';
import { authHeader } from '@/helpers/authHeader';
import { endpoints } from '@/backend/endpoints';

function fetchStagedBotInfo(stagedBotId) {
  const params = {
    staged_bot_id: stagedBotId,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.stagedBotInfo,
    params,
    method: 'get',
    headers: auth,
  });
}

function fetchVariantSetInfo(variantSetId) {
  const params = {
    bot_variant_set_id: variantSetId,
  };
  const auth = authHeader();
  return axios({
    url: endpoints.variantSetInfo,
    params,
    method: 'get',
    headers: auth,
  });
}

const restcalls = {
  fetchStagedBotInfo,
  fetchVariantSetInfo,
};

export default restcalls;
