<template>
  <PvButton
    v-bind="$attrs"
    :type="type"
    @click="$emit('click', $event)"
  >
    <slot />
  </PvButton>
</template>

<script>

export default {
  name: 'ButtonBase',
  props: {
    type: {
      type: String,
      default: 'button',
    },
  },
  emits: ['click'],
};
</script>
