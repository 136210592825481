export default {
  install: (app) => {
    function confirmDialogPromise(params) {
      return new Promise((resolve) => {
        app.config.globalProperties.$confirm.require({
          ...params,
          accept: () => resolve(true),
          reject: () => resolve(false),
        });
      });
    }
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$swPromiseConfirm = confirmDialogPromise;
  },
};
