function openSidebar(event, binding) {
  const { modifiers, instance } = binding;
  const modifierKeys = Object.keys(modifiers ?? {});
  if (!modifierKeys.length) return;
  const sidebarRefName = modifierKeys[0];

  const sidebar = instance.$refs[sidebarRefName];
  if (!sidebar) return;
  sidebar.visible = !sidebar.visible;
}

export default {
  // eslint-disable-next-line no-unused-vars
  mounted(el, binding, vnode, prevVnode) {
    el.addEventListener('click', (e) => openSidebar(e, binding));
  },
  // eslint-disable-next-line no-unused-vars
  beforeUnmount(el, binding, vnode, prevVnode) {
    el.removeEventListener('click', (e) => openSidebar(e, binding));
  },
};
