<template>
  <PvButton
    v-if="sidebarVisible"
    v-tooltip="tooltipText"
    class="size-10 ml-2 bg-transparent border-0"
    size="small"
    @click="templateSetSidebarCompact(!sidebarCompact)"
  >
    <font-awesome-icon
      icon="bars"
      :class="['ease-in-out', { 'scale-x-[0.25]': sidebarCompact }]"
    />
  </PvButton>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'SidebarCollapseBtn',
  computed: {
    ...mapState('templateStore', ['sidebarCompact', 'sidebarVisible', 'localizationObj']),
    tooltipText() {
      if (this.sidebarCompact) return this.localizationObj.sidebar.disableCompactSidebar;
      return this.localizationObj.sidebar.enableCompactSidebar;
    },
  },
  methods: {
    ...mapMutations('templateStore', ['templateSetSidebarCompact']),
  },
};
</script>
