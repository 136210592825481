import botBackend from '@/backend/botManipulationBackend';

const initialState = {
  supSearchState: {},
  lastUpdatedTimestamp: 0,
  isUpdating: false,
};

const getters = {
  supSearchState: (state) => state.supSearchState,
  getLastUpdatedTimestamp: (state) => state.lastUpdatedTimestamp,
};

const mutations = {
  setSupSearchState(state, { newState }) {
    state.supSearchState = newState;
  },
  setLastUpdatedTimestamp(state, { timestamp }) {
    state.lastUpdatedTimestamp = timestamp;
  },
  setIsUpdating(state, value) {
    state.isUpdating = value;
  },
};

const actions = {
  async sendSupSearchSettings({ dispatch }, { settings }) {
    // Wait for control to confirm them
    try {
      await botBackend.sendSupSearchSettings(settings);
    } catch (error) {
      dispatch('templateStore/templateSendNotification', {
        title: 'Failed to update AI Engine settings',
        text: error.response.data.error,
        severity: 'error',
        toast: true,
      }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
