export default Object.freeze({
  NO_TOKEN: 'NO_TOKEN',
  ERROR_REFRESHING_TOKEN: 'ERROR_REFRESHING_TOKEN',
  NO_TIME_FOR_REFRESH: 'NO_TIME_FOR_REFRESH',
  ACCESS_TOKEN_STILL_VALID: 'ACCESS_TOKEN_STILL_VALID',
});

export const ChatSystemManipulationAction = Object.freeze({
  ACTIVATE_CHATSYSTEM: 'ONLINE',
  DEACTIVATE_CHATSYSTEM: 'INVISIBLE',
  RESET_CHATSYSTEM_CONNECTION: 'OFFLINE',
});
