import botBackend from '@/backend/botManipulationBackend';

const initialState = {
  speechState: {},
  lastUpdatedTimestamp: 0,
  isUpdating: false,
};

const getters = {
  speechState: (state) => state.speechState,
  getLastUpdatedTimestamp: (state) => state.lastUpdatedTimestamp,
};

const mutations = {
  setSpeechState(state, { newState }) {
    state.speechState = newState;
  },
  setLastUpdatedTimestamp(state, { timestamp }) {
    state.lastUpdatedTimestamp = timestamp;
  },
  setIsUpdating(state, value) {
    state.isUpdating = value;
  },
};

const actions = {
  async sendSpeechSettings({ dispatch }, { settings }) {
    // Wait for control to confirm them
    try {
      await botBackend.sendSpeechSettings(settings);
    } catch (error) {
      dispatch('templateStore/templateSendNotification', {
        title: 'Failed to update speech settings',
        text: error.response.data.error,
        severity: 'error',
        toast: true,
      }, { root: true });
    }
  },
  async reloadSpeechVoices({ dispatch }) {
    try {
      await botBackend.sendSpeechCommand('reload-voices');
    } catch (error) {
      dispatch('templateStore/templateSendNotification', {
        title: 'Failed to reload speech voices',
        text: error.response.data.error,
        severity: 'error',
        toast: true,
      }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
