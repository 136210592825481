<template>
  <Message v-bind="$attrs">
    <template
      v-if="icon"
      #icon
    >
      <span class="w-4 flex justify-center">
        <FontAwesomeIcon v-bind="{ icon }" />
      </span>
    </template>
    <slot />
  </Message>
</template>

<script>
import Message from 'primevue/message';
/*
  A small wrapper component for SwMessage
  In v3 of primevue it automatically showed an icon, which was removed
  in v4. This adds back a similar font awesome icon unless "inline" is true.
  "inline" is supposed to mimic PvInlineMessage which was also removed in v4.
*/
export default {
  components: { Message },
  props: {
    inline: { type: Boolean, default: false },
  },
  iconMap: {
    error: 'circle-xmark',
    secondary: '',
    info: 'info',
    success: 'check',
    warn: 'exclamation',
    contrast: '',
  },
  computed: {
    icon() {
      if (this.inline) return null;
      const { icon, severity } = this.$attrs;
      // "icon" can be set to an empty string if no icon is wanted.
      return icon ?? (severity ? this.$options.iconMap[severity] : null);
    },
  },
};
</script>
