import axios from 'axios';
import { endpoints } from '@/backend/endpoints';
import { authHeader } from '@/helpers/authHeader';
import { objToCamel, objToSnake } from 'supwiz/util/data';

const userState = {
  users: {},
  isFetchingUsers: false,
  groups: {},
  isFetchingGroups: false,
};
const getters = {
  usersArray: (state) => Object.values(state.users)
    .sort((a, b) => (a.id && b.id ? b.id - a.id : null)),
  groupOptions(state) {
    return Object.values(state.groups).map((x) => ({ value: x.id, text: x.name }));
  },
};
const mutations = {
  setUsers(state, users) {
    users.forEach((e) => {
      state.users[e.id] = objToCamel(e);
    });
  },
  setIsFetchingUsers(state, value) {
    state.isFetchingUsers = value;
  },
  setGroups(state, groups) {
    groups.forEach((e) => {
      state.groups[e.id] = e;
    });
  },
  setIsFetchingGroups(state, value) {
    state.isFetchingGroups = value;
  },
};
const actions = {
  async fetchUsers({ commit, dispatch }) {
    try {
      commit('setIsFetchingUsers', true);
      const config = { headers: authHeader() };
      const resp = await axios.get(endpoints.user, config);
      commit('setUsers', resp.data);
    } catch (error) {
      dispatch('templateStore/templateSendNotification', {
        title: 'Failed to fetch users',
        text: error.message,
        severity: 'error',
        toast: true,
      }, { root: true });
    } finally {
      commit('setIsFetchingUsers', false);
    }
  },
  async addUser({ dispatch }, { newItem }) {
    const auth = { headers: authHeader() };
    const itemData = objToSnake(newItem);
    try {
      const { data: addedItem } = await axios.post(endpoints.user, itemData, auth);
      if ('id' in addedItem || addedItem.length) {
        return true;
      }
      dispatch('templateStore/templateSendNotification', {
        title: 'Unexpected Response',
        text: 'Got unexpected response when adding user',
        severity: 'error',
        toast: true,
      }, { root: true });
      return false;
    } catch (error) {
      dispatch('templateStore/templateSendNotification', {
        title: 'Failed to add user',
        text: error.response.data[0] || error.message,
        severity: 'error',
        toast: true,
      }, { root: true });
      return false;
    }
  },
  async deleteUser({ dispatch }, { item }) {
    const auth = { headers: authHeader() };
    try {
      await axios.delete(`${endpoints.user}${item.id}/`, auth);
      return true;
    } catch (error) {
      dispatch('templateStore/templateSendNotification', {
        title: 'Failed to delete user',
        text: error.message,
        severity: 'error',
        toast: true,
      }, { root: true });
      return false;
    }
  },
  async updateUser({ dispatch }, item) {
    const auth = { headers: authHeader() };
    const { ...data } = objToSnake(item);
    try {
      await axios.patch(`${endpoints.user}${item.id}/`, data, auth);
      return true;
    } catch (error) {
      dispatch('templateStore/templateSendNotification', {
        title: 'Failed to update user',
        text: error.message,
        severity: 'error',
        toast: true,
      }, { root: true });
      return false;
    }
  },
  async fetchGroups({ commit, dispatch }) {
    try {
      commit('setIsFetchingGroups', true);
      const config = { headers: authHeader() };
      const resp = await axios.get(endpoints.group, config);
      commit('setGroups', resp.data);
    } catch (error) {
      dispatch('templateStore/templateSendNotification', {
        title: 'Failed to fetch groups',
        text: error.message,
        severity: 'error',
        toast: true,
      }, { root: true });
    } finally {
      commit('setIsFetchingGroups', false);
    }
  },
};

export default {
  namespaced: true,
  state: userState,
  getters,
  actions,
  mutations,
};
