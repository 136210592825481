import botBackend from '@/backend/botManipulationBackend';

const initialState = {
  smsState: {},
  lastUpdatedTimestamp: 0,
  isUpdating: false,
};

const getters = {
  smsState: (state) => state.smsState,
  getLastUpdatedTimestamp: (state) => state.lastUpdatedTimestamp,
};

const mutations = {
  setSmsState(state, { newState }) {
    state.smsState = newState;
  },
  setLastUpdatedTimestamp(state, { timestamp }) {
    state.lastUpdatedTimestamp = timestamp;
  },
  setIsUpdating(state, value) {
    state.isUpdating = value;
  },
};

const actions = {
  async sendSmsSettings({ dispatch }, { settings }) {
    // Wait for control to confirm them
    try {
      await botBackend.sendSmsSettings(settings);
    } catch (error) {
      dispatch('templateStore/templateSendNotification', {
        title: 'Failed to update SMS settings',
        text: error.response.data.error,
        variant: 'danger',
        toast: true,
      }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
