<template>
  <component
    :is="tag"
    :class="[
      'col',
      alignSelfClass,
      colsClasses,
      offsetClasses,
      orderClasses,
    ]"
  >
    <slot />
  </component>
</template>

<script>
const alignSelfClassMap = Object.freeze({
  start: 'self-start',
  center: 'self-center',
  end: 'self-end',
  baseline: 'self-baseline',
  stretch: 'self-stretch',
});

const numberOrString = {
  type: [String, Number],
  default: '',
};

export default {
  name: 'SwCol',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    alignSelf: {
      type: [String, null],
      default: null,
    },
    col: {
      type: Boolean,
      default: false,
    },
    cols: numberOrString,
    sm: numberOrString,
    md: numberOrString,
    lg: numberOrString,
    xl: numberOrString,
    offset: numberOrString,
    offsetLg: numberOrString,
    offsetMd: numberOrString,
    offsetSm: numberOrString,
    offsetXl: numberOrString,
    order: numberOrString,
    orderLg: numberOrString,
    orderMd: numberOrString,
    orderSm: numberOrString,
    orderXl: numberOrString,
  },
  computed: {
    alignSelfClass() {
      if (!this.alignSelf) return '';
      return alignSelfClassMap[this.alignSelf] ?? '';
    },
    colsClasses() {
      let result = '';
      if (this.cols) result += `col-${this.cols} `;

      ['sm', 'md', 'lg', 'xl'].forEach((breakpoint) => {
        if (this[breakpoint]) {
          result += `col-${breakpoint}-${this[breakpoint]} `;
        }
      });
      return result;
    },
    offsetClasses() {
      let result = '';
      if (this.offset) result += `offset-${this.offset} `;

      ['offsetSm', 'offsetMd', 'offsetLg', 'offsetXl'].forEach((breakpoint) => {
        if (this[breakpoint]) {
          result += `offset-${breakpoint}-${this[breakpoint]} `;
        }
      });
      return result;
    },
    orderClasses() {
      let result = '';
      if (this.order) result += `order-${this.order} `;

      ['orderSm', 'orderMd', 'orderLg', 'orderXl'].forEach((breakpoint) => {
        if (this[breakpoint]) {
          result += `order-${breakpoint}-${this[breakpoint]} `;
        }
      });
      return result;
    },
  },
};
</script>
