import Cookies from 'js-cookie';
import { createRouter, createWebHistory } from 'vue-router';

import LoggedUserBase from '@/pages/LoggedUserBase.vue';

import LoginPage from '@/pages/LoginPage.vue';
import LogoutPage from '@/pages/LogoutPage.vue';

import store from '@/store/index';
import TokenStatus from '@/enums/tokenStatus';
import { closeSocket } from '@/backend/socketBackend';
import { endpoints } from '@/backend/endpoints';

// Routes requiring auth should be declared with meta.requiresAuth = true
// See https://router.vuejs.org/guide/advanced/meta.html
const routes = [
  {
    path: '/',
    redirect: {
      name: 'manage',
    },
  },
  {
    path: '/manage',
    component: LoggedUserBase,
    meta: {
      requiresAuth: true,
      breadcrumb: { display: ' ', link: null },
    },
    children: [
      {
        path: '',
        name: 'manage',
        component: () => import('@/pages/ManagePage.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: { display: 'Manage', link: { name: 'manage' } },
        },
      },
      {
        path: 'config',
        name: 'config',
        component: () => import('@/pages/ConfigPage.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: { display: 'Configuration', link: { name: 'config' } },
        },
      },
      {
        path: 'audit-log',
        name: 'audit-log',
        component: () => import('@/pages/AuditLogTable.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: { display: 'Audit Log', link: { name: 'audit-log' } },
        },
      },
      {
        path: 'advanced',
        name: 'advanced',
        component: () => import('@/pages/AdvancedPage.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: { display: 'Advanced', link: { name: 'advanced' } },
        },
      },
      {
        path: 'external',
        name: 'external',
        component: () => import('@/pages/ExternalPage.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: { display: 'SunCo Switchboard', link: { name: 'external' } },
        },
      },
      {
        path: 'user-management',
        name: 'user-management',
        component: () => import('@/pages/UserManagement.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: { display: 'User Management', link: { name: 'user-management' } },
        },
      },
    ],
  },

  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {
      breadcrumb: { display: ' ', link: null },
    },
  },
  {
    path: '/login-supwiz',
    name: 'login-supwiz',
    beforeEnter: () => window.location.replace(endpoints.ssoLoginSupwiz),
  },
  {
    path: '/logout',
    component: LogoutPage,
    name: 'logout',
    meta: {
      breadcrumb: { display: ' ', link: null },
    },
  },
  {
    path: '/aad-auth',
    name: 'aad-auth',
    beforeEnter: (to, from, next) => {
      const { originalPath } = to.query;
      if (to.query.success === 'true') {
        const refreshToken = Cookies.get('jwt_refresh_token');
        const accessToken = Cookies.get('jwt_access_token');
        Cookies.remove('jwt_refresh_token');
        Cookies.remove('jwt_access_token');
        if (refreshToken && accessToken) {
          store.commit('auth/updateAccessToken', accessToken);
          store.commit('auth/updateRefreshToken', refreshToken);
          store.commit('auth/updateTokenStatus', TokenStatus.ACCESS_TOKEN_STILL_VALID);
          store.dispatch('auth/scheduleNextTokenRefresh');
          if (originalPath) {
            next(originalPath);
          } else {
            next({ name: 'manage' });
          }
        } else {
          next({ name: 'login', query: { aad_error: 'unexpected_error' } });
        }
      } else {
        next({ name: 'login', query: { aad_error: to.query.aad_error, originalPath } });
      }
    },
  },
  {
    path: '/sso-auth',
    name: 'sso-auth',
    beforeEnter: (to, from, next) => {
      const { originalPath } = to.query;
      if (to.query.success === 'true') {
        const refreshToken = Cookies.get('jwt_refresh_token');
        const accessToken = Cookies.get('jwt_access_token');
        Cookies.remove('jwt_refresh_token');
        Cookies.remove('jwt_access_token');
        if (refreshToken && accessToken) {
          store.commit('auth/updateAccessToken', accessToken);
          store.commit('auth/updateRefreshToken', refreshToken);
          store.commit('auth/updateTokenStatus', TokenStatus.ACCESS_TOKEN_STILL_VALID);
          store.dispatch('auth/scheduleNextTokenRefresh');
          if (originalPath) {
            next(originalPath);
          } else {
            next({ name: 'manage' });
          }
        } else {
          next({ name: 'login', query: { aad_error: 'unexpected_error', originalPath } });
        }
      } else {
        next({ name: 'login', query: { aad_error: to.query.aad_error, originalPath } });
      }
    },
  },
  // Add additional entries here
  {
    // Catch all
    path: '/:pathMatch(.*)*',
    redirect: '/manage',
  },
];

const base = process.env.NODE_ENV !== 'development' ? window.botadminPublicPath : '/';
const router = createRouter({
  history: createWebHistory(base),
  routes,
});

// Snatched from: https://router.vuejs.org/guide/advanced/meta.html
router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some((record) => record.meta.requiresAuth);

  // We need to call the action refreshTokenIfNeededAndPossible before fetching the value of
  // tokenSeemsOk because it is dependent on a state that might not be initialized before
  // refreshTokenIfNeededAndPossible is called.
  if (authRequired) {
    await store.dispatch('auth/refreshTokenIfNeededAndPossible');
  }
  const tokenSeemsOk = store.getters['auth/tokenSeemsOk'];

  if (authRequired && !tokenSeemsOk) {
    return next({ name: 'login' });
  }

  if (to.path.includes('login') || to.path.includes('logout')) {
    store.commit('sidebar/setShowSidebar', false);
    store.commit('auth/updateLoggedIn', false);
    closeSocket();
  } else if (store.state.auth.accessToken && tokenSeemsOk) {
    if (!store.state.auth.user) {
      store.dispatch('auth/getUser');
    }
    store.commit('auth/updateLoggedIn', true);
    store.commit('sidebar/setShowSidebar', true);
    await store.dispatch('auth/scheduleNextTokenRefresh');
  }
  return next();
});

export default router;
