<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 158.8">
    <path fill="#0c1436" stroke-width="0px" d="M236.53,104.27c3.4-6.34,5.12-13.59,5.12-21.53s-1.69-15.37-5.03-21.72c-3.33-6.32-8.01-11.37-13.93-15.02-5.91-3.64-12.67-5.49-20.09-5.49-.1,0-.19,0-.29,0h-.32c-.19,0-.3,0-.41,0-.09,0-.19,0-.29,0-7.42,0-14.18,1.85-20.09,5.49-5.92,3.65-10.6,8.7-13.93,15.02-3.33,6.32-5.02,13.6-5.03,21.63v.93s0,1.84,0,1.84v73.37h18.57v-39.61l2.31,1.18.84.43c5.2,2.65,11.03,4,17.33,4,.1,0,.2,0,.3,0h.3s.04-.01.04-.01h.04s.31,0,.31,0c.11,0,.21,0,.31,0,7.42,0,14.15-1.85,19.99-5.48,5.87-3.65,10.56-8.71,13.94-15.04ZM222.68,95.47c-2,3.8-4.77,6.83-8.25,9.02-3.5,2.21-7.63,3.33-12.25,3.33s-8.81-1.11-12.47-3.3c-3.7-2.2-6.53-5.27-8.41-9.1-1.84-3.75-2.78-8.07-2.78-12.85s1-9.12,2.99-12.82c1.99-3.73,4.8-6.73,8.35-8.93,3.57-2.2,7.65-3.32,12.15-3.32,4.74,0,8.92,1.12,12.43,3.33,3.48,2.19,6.25,5.19,8.25,8.92,1.98,3.71,2.98,8.03,2.98,12.83s-1,9.14-2.98,12.9Z" />
    <path fill="#0c1436" stroke-width="0px" d="M269.1,120.21c5.53,2.93,11.89,4.42,18.89,4.42s13.32-1.48,18.8-4.41c5.42-2.9,9.73-7.04,12.8-12.31,3.08-5.28,4.64-11.49,4.64-18.46v-47.16h-18.74v46.63c0,5.82-1.56,10.47-4.63,13.8-3.12,3.39-7.45,5.1-12.87,5.1-3.53,0-6.67-.74-9.32-2.21-2.75-1.51-4.84-3.77-6.21-6.71-1.3-2.8-1.96-6.16-1.96-9.99v-46.63h-18.92v47.16c0,6.98,1.56,13.19,4.64,18.46,3.07,5.26,7.4,9.4,12.88,12.3Z" />
    <polygon fill="#0c1436" stroke-width="0px" points="377.45 58.03 375.47 60.59 374.76 61.52 335.24 112.73 335.24 123.04 402.95 123.04 402.95 107.29 366.36 107.29 365.19 107.29 361.96 107.29 363.93 104.73 364.64 103.8 404.01 52.59 404.01 42.28 338.58 42.28 338.58 58.03 373.04 58.03 374.22 58.03 377.45 58.03" />
    <polygon fill="#0c1436" stroke-width="0px" points="454.06 58.03 452.08 60.59 451.36 61.52 411.84 112.73 411.84 123.04 479.56 123.04 479.56 107.29 442.97 107.29 441.8 107.29 438.57 107.29 440.53 104.73 441.25 103.8 480.62 52.59 480.62 42.28 415.19 42.28 415.19 58.03 449.65 58.03 450.82 58.03 454.06 58.03" />
    <path fill="#0c1436" stroke-width="0px" d="M552.41,101.73c-2.46,2.45-5.29,4.32-8.42,5.58-3.76,1.51-8,2.27-12.61,2.27-5.17,0-9.79-1.1-13.73-3.26-4.01-2.2-7.13-5.44-9.26-9.64-.82-1.62-1.5-3.4-2-5.29l-.19-.73-.54-2h2.07s.75,0,.75,0l60.45-.15c.23-1.14.38-2.21.45-3.19.11-1.54.17-3.06.17-4.51,0-7.82-1.69-14.88-5.02-20.98-3.31-6.07-8.02-10.89-13.98-14.31-5.98-3.43-12.85-5.17-20.41-5.17-8.04,0-15.38,1.86-21.83,5.53-6.45,3.67-11.6,8.74-15.32,15.07-3.72,6.32-5.6,13.6-5.6,21.63s1.91,15.47,5.68,21.79c3.77,6.33,9.04,11.4,15.66,15.07,6.63,3.68,14.31,5.54,22.82,5.54,6.61,0,12.79-1.19,18.36-3.53,4.93-2.07,9.35-5.11,13.16-9.04l-10.67-10.67ZM506.19,73.73l.2-.73c.46-1.72,1.07-3.35,1.82-4.86,2-4.07,4.92-7.25,8.69-9.46,3.73-2.19,8.13-3.29,13.08-3.29s8.78.99,12.04,2.95c3.29,1.97,5.88,4.89,7.7,8.66.86,1.78,1.53,3.79,2.01,5.98l.15.7.42,1.92h-1.97s-.71,0-.71,0l-41.12.12h-.76s-2.08,0-2.08,0l.54-2.01Z" />
    <rect fill="#0c1436" stroke-width="0px" x="581.26" y="0" width="18.74" height="123.04" />
    <path fill="#ff9903" stroke-width="0px" d="M128.3,31.76c-4.78-13.54-15.51-24.27-29.05-29.05-4.97-1.76-10.31-2.71-15.88-2.71s-10.91.96-15.88,2.71c-2.77.98-5.43,2.21-7.94,3.66-1.37.79-2.7,1.65-3.97,2.57-7.82,5.63-13.87,13.56-17.14,22.82-1.76,4.97-2.71,10.31-2.71,15.88v15.88c0,4.11-.52,8.1-1.5,11.91-1.78,6.92-5.08,13.24-9.52,18.57-.36.44-.73.86-1.11,1.28h59.77c5.57,0,10.91-.96,15.88-2.71,9.25-3.27,17.19-9.32,22.82-17.14,2.61-3.62,4.72-7.63,6.23-11.91,1.76-4.97,2.71-10.31,2.71-15.88s-.96-10.91-2.71-15.88ZM99.25,70.45c-4.5,3.15-9.97,4.98-15.88,4.98h-27.79v-27.79c0-5.91,1.84-11.38,4.98-15.88,1.88-2.7,4.23-5.05,6.93-6.93,2.35-1.65,4.97-2.93,7.78-3.78,2.56-.78,5.28-1.2,8.1-1.2,5.91,0,11.38,1.84,15.88,4.98,2.7,1.88,5.05,4.23,6.93,6.93,3.15,4.5,4.98,9.97,4.98,15.88s-1.84,11.38-4.98,15.88c-1.88,2.7-4.23,5.05-6.93,6.93Z" />
    <path fill="#ff375b" stroke-width="0px" d="M58.18,107.19c-.2.22-.41.46-.6.7-2.4,2.88-4.2,6.31-5.16,10.05-.53,2.06-.82,4.23-.82,6.45v8.6c0,3.02-.52,5.91-1.47,8.6-1.77,5.01-5.05,9.31-9.28,12.36-.69.5-1.41.96-2.15,1.39-1.36.79-2.8,1.46-4.3,1.99-2.69.95-5.58,1.47-8.6,1.47s-5.91-.52-8.6-1.47c-7.33-2.59-13.14-8.4-15.73-15.73-.95-2.69-1.47-5.58-1.47-8.6s.52-5.91,1.47-8.6c.82-2.32,1.96-4.49,3.37-6.45,3.05-4.23,7.35-7.51,12.36-9.28,2.69-.95,5.59-1.47,8.6-1.47h32.38Z" />
  </svg>
</template>
