<template>
  <div
    :class="[
      'flex flex-col relative',
      {
        'min-w-0 max-w-0': !sidebarVisible,
        'min-w-60 max-w-60': sidebarVisible && !sidebarCompact,
        'min-w-16 max-w-16': sidebarCompact && sidebarVisible,
      }]"
  >
    <slot name="before-nav" />
    <div
      v-if="isLoggedIn"
      class="p-3 flex flex-col overflow-y-auto"
    >
      <SidebarSearch
        v-if="showSearch && !sidebarCompact"
        v-model="query"
        class="mb-4"
      />
      <template v-if="filteredPages.length">
        <component
          :is="sidebarCompact ? 'NavCompact' : 'NavNormal'"
          :pages="filteredPages"
          :is-searching="query !== ''"
        />
      </template>
      <span
        v-else
        class="text-white text-center"
      >
        {{ localizationObj.sidebar.search.noResults }}
      </span>
    </div>
    <div class="mt-auto block">
      <slot
        v-if="!sidebarCompact"
        name="after-nav"
      />
      <SidebarCollapseBtn v-if="showCollapseBtn" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SidebarSearch from './SidebarSearch.vue';
import NavNormal from './navigation/NavNormal.vue';
import NavCompact from './navigation/NavCompact.vue';
import SidebarCollapseBtn from './SidebarCollapseBtn.vue';

export default {
  name: 'AppSidebar',
  components: {
    SidebarSearch,
    NavNormal,
    NavCompact,
    SidebarCollapseBtn,
  },
  props: {
    showSearch: {
      type: Boolean,
      default: false,
    },
    showCollapseBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      query: '',
    };
  },
  computed: {
    ...mapState('templateStore', [
      'sidebarVisible',
      'pages',
      'isLoggedIn',
      'sidebarCompact',
      'localizationObj',
    ]),
    filteredPages() {
      if (this.query === '') return this.pages;
      const result = [];
      for (let i = 0; i < this.pages.length; i++) {
        const page = this.pages[i];
        if (page.to && (page.label.toLowerCase().includes(this.query.toLowerCase())
            || (page.tags && page.tags.toLowerCase().includes(this.query.toLowerCase())))) {
          result.push({
            label: page.label,
            to: page.to,
          });
        } else if (page.items && page.items.length > 0) {
          page.items.forEach((child) => {
            if (child.label.toLowerCase().includes(this.query.toLowerCase())
                || page.label.toLowerCase().includes(this.query.toLowerCase())
                || (child.tags && child.tags.toLowerCase().includes(this.query.toLowerCase()))
                || (page.tags && page.tags.toLowerCase().includes(this.query.toLowerCase()))
            ) {
              result.push({
                label: `${page.label} - ${child.label}`,
                to: child.to,
              });
            }
          });
        }
      }
      return result;
    },
  },
};
</script>
