const initialState = {
  socketIsConnected: false,
};

const metaActions = {
  namespaced: true,
  state: initialState,
  getters: {
    socketIsConnected: (state) => state.socketIsConnected,
  },
  actions: {
    changeIsConnected({ commit }, booleanForConnectedness) {
      commit('changeSocketConnectedness', booleanForConnectedness);
    },
  },
  mutations: {
    changeSocketConnectedness(state, newValue) {
      state.socketIsConnected = newValue;
    },
  },
};

export default metaActions;
