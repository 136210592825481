export function deepEqualsJson(a, b) {
  if (typeof a !== typeof b) {
    return false;
  }
  if (a instanceof Array) {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      if (!deepEqualsJson(a[i], b[i])) {
        return false;
      }
    }
    return true;
  }
  if (a instanceof Object) {
    if (b === null) { // Corner case as javascript is stupid and defines typeof null === "object"
      return false;
    }
    if (!deepEqualsJson(Object.keys(a).sort(), Object.keys(b).sort())) {
      return false;
    }
    for (const key of Object.keys(a)) {
      if (!deepEqualsJson(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }
  return a === b;
}

export function deepCopyJson(val) {
  if (val instanceof Array) {
    const result = [];
    for (const elem of val) {
      result.push(deepCopyJson(elem));
    }
    return result;
  }
  if (val instanceof Object) {
    const result = {};
    for (const key of Object.keys(val)) {
      result[key] = deepCopyJson(val[key]);
    }
    return result;
  }
  return val;
}

/**
 * Merge 2 objects together
 * @param {object} originalObject - original object
 * @param {object} objectToMerge - object to merge or overwrite into original object
 * @example
 * mergeObject({ a: 1 }, { a: 2, b: 3 })
 * // returns { a: 2, b: 3 }
 * @returns {object} merged object
 */
export function mergeObjects(originalObject, objectToMerge) {
  if (!originalObject || typeof originalObject !== 'object') return objectToMerge;
  const resultObj = structuredClone(originalObject);
  Object.entries(objectToMerge).forEach(([key, value]) => {
    let result;
    if (typeof value === 'object' && !Array.isArray(value)) {
      result = mergeObjects(resultObj[key], value);
    } else {
      result = value;
    }
    resultObj[key] = result;
  });
  return resultObj;
}

// SNAKE-CAMEL-CONVERSION
export function strToSnake(str) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}
export function objToSnake(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => {
    // for some reason js considers null and [] an object, so additional check is required
    if (typeof v === 'object' && v && !Array.isArray(v)) {
      return [strToSnake(k), objToSnake(v)];
    }
    return [strToSnake(k), v];
  }));
}
export function strToCamel(str) {
  return str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase().replace('-', '').replace('_', ''),
  );
}
export function objToCamel(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => {
    // for some reason js considers null and [] an object, so additional check is required
    if (typeof v === 'object' && v && !Array.isArray(v)) {
      return [strToCamel(k), objToCamel(v)];
    }
    return [strToCamel(k), v];
  }));
}
export function camelStrToText(str) {
  return str
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, (e) => e.toUpperCase());
}
export function snakeCaseToText(str) {
  return str ? str.split('_').map((e) => e.charAt(0).toUpperCase() + e.slice(1)).join(' ') : null;
}
export const anonymizationTypes = Object.freeze([
  { value: 'swml', text: 'Built-in recognizer' },
  { value: 'regex', text: 'Regular Expression' },
]);

export const specialNersDict = Object.freeze({
  ListNER: 'List NER',
  CPRNumberRecognizer: 'CPR Number Recognizer',
  IPRecognizer: 'IP Recognizer',
});

// use for charts and other features that require many colors
export const matchingColors = [
  'rgb(91, 116, 240)', // #5B74F0 - Blue
  'rgb(180, 66, 214)', // #B442D6 - Purple
  'rgb(235, 21, 98)', // #EB1562 - Pink
  'rgb(36, 130, 127)', // #24827F - Teal
  'rgb(212, 127, 0)', // #D47F00 - Orange
  'rgb(78, 100, 202)', // #4E64CA - Royal Blue
  'rgb(209, 140, 230)', // #D18CE6 - Lavender
  'rgb(56, 161, 157)', // #38A19D - Dark Turquoise
  'rgb(255, 194, 102)', // #FFC266 - Light Orange
  'rgb(243, 115, 161)', // #F373A1 - Light Pink
  'rgb(154, 170, 246)', // #9AAAF6 - Light Blue
  'rgb(52, 192, 188)', // #34C0BC - Aqua
  'rgb(255, 72, 149)', // #FF4895 - Hot Pink
  'rgb(206, 146, 219)', // #CE92DB - Light Purple
  'rgb(103, 243, 239)', // #67F3EF - Cyan
  'rgb(200, 156, 90)', // #C89C5A - Brownish Yellow
  'rgb(255, 174, 251)', // #FFAEFB - Pale Pink
  'rgb(190, 254, 151)', // #BEFE97 - Light Green
  'rgb(255, 123, 200)', // #FF7BC8 - Bubblegum Pink
  'rgb(190, 216, 255)', // #BED8FF - Baby Blue
  'rgb(211, 187, 70)', // #D3BB46 - Golden Yellow
  'rgb(255, 219, 255)', // #FFDBFF - Soft Pink
  'rgb(100, 208, 217)', // #64D0D9 - Sky Blue
  'rgb(236, 145, 145)', // #EC9191 - Light Red
  'rgb(252, 182, 181)', // #FCB6B5 - Peach
  'rgb(183, 189, 219)', // #B7BDDB - Periwinkle
];

export function shuffledMatchingColors() {
  const shuffledArray = matchingColors.slice();
  // Fisher-Yates shuffle algorithm
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

export function guidGenerator() {
  /**
   * @return {string}
   */
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (`${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`);
}
