import axios from 'axios';
import { authHeader } from '@/helpers/authHeader';
import { getBaseRESTAPIURL } from '@/backend/endpoints';
import app from '@/app';

const BASE_URL = getBaseRESTAPIURL();

const endpoints = {
  login: `${BASE_URL}/auth/obtain-token/`,
  verifyToken: `${BASE_URL}/verify-token`,
  refreshToken: `${BASE_URL}/auth/refresh-token/`,
  bot: `${BASE_URL}/bot`,
  deployBot: `${BASE_URL}/bot/deploy`,
};

async function login(username, password) {
  const payload = {
    username,
    password,
  };
  try {
    const response = await axios.post(endpoints.login, payload);
    const statusCode = response.status;
    if (statusCode === 200) {
      // Store JSON Web Token
      const data = response.data;
      return {
        status: 'success',
        tokens: {
          access: data.access,
          refresh: data.refresh,
        },
      };
    }
    return {
      status: 'unhandled',
      response,
    };
  } catch (error) {
    app.config.globalProperties.$log.debug(error);
    return {
      status: 'error',
      response: error.response,
    };
  }
}

async function refreshAccessToken(encodedRefreshToken) {
  return axios.post(endpoints.refreshToken, {
    refresh: encodedRefreshToken,
  });
}

async function verifyToken(token) {
  const payload = {
    token,
  };
  const auth = authHeader();
  const response = await axios.post(endpoints.verifyToken, payload, {
    headers: auth,
  });
  if (response.statusCode === 200) {
    return 'success';
  }
  return 'rejected';
}
const actions = {
  login,
  verifyToken,
  refreshAccessToken,
};

export default actions;
