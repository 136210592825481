<template>
  <div class="flex h-full flex-col items-center justify-center">
    <div class="max-w-sm text-center">
      <PvCard>
        <template #title>
          You are now signed out
        </template>
        <template #content>
          <RouterLink :to="{ name: 'login' }" class="my-4 block">
            Sign in again
          </RouterLink>
        </template>
        <template #footer>
          <div class="my-3">
            <PuzzelLogoDark class="w-40" />
          </div>
        </template>
      </PvCard>
    </div>
  </div>
</template>

<script>
import { closeSocket } from '@/backend/socketBackend';
import PuzzelLogoDark from 'supwiz/components-vue3/template/header/PuzzelLogoDark.vue';

export default {
  name: 'LogoutPage',
  components: { PuzzelLogoDark },
  mounted() {
    this.$store.dispatch('auth/logout');
    closeSocket();
  },
};
</script>
