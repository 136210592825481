<template>
  <div class="relative">
    <div class="relative">
      <ButtonIcon
        :aria-label="buttonTitle"
        aria-haspopup
        aria-controls="notifications-menu"
        class="size-9"
        icon="bell"
        @click="toggleNotifications"
      />
      <PvBadge
        v-if="hasNotifications"
        class="bottom-0 right-0 absolute translate-x-1/3 translate-y-1/3 z-50"
        severity="danger"
        :value="badgeValue"
      />
    </div>
    <PvPopover
      id="notifications-menu"
      ref="menu"
      :pt="{
        content: () => ({ class: 'flex flex-col gap-3' }),
      }"
    >
      <PvPanel
        v-for="item in notificationsSorted"
        :key="item.timestamp"
        collapsed
        toggleable
        :pt="{
          root: () => ({ class: 'w-96 border-0' }),
          header: () => ({ class: 'p-0' }),
          content: () => ({ class: 'py-2 px-5 text-sm' }),
          footer: () => ({ class: 'py-0 px-5' }),
        }"
      >
        <template #header>
          <div class="flex items-center gap-3">
            <PvBadge :severity="getSeverity(item.severity)" />
            <div class="flex flex-col">
              <span class="truncate text-md">{{ item.title }}</span>
              <small><TimeAgo :epoch="item.timestamp" /></small>
            </div>
          </div>
        </template>
        <p class="m-0 text-wrap">
          {{ item.text }}
        </p>
        <template #footer>
          <div class="flex justify-center">
            <PvButton
              :label="localizationObj.notifications.clearNotification"
              size="small"
              text
              @click="removeSingleNotification(item.timestamp)"
            />
          </div>
        </template>
      </PvPanel>
      <div class="flex flex-col justify-center">
        <template v-if="hasNotifications">
          <PvDivider class="my-3" />
          <PvButton
            v-if="hasNotifications"
            :label="localizationObj.notifications.clearNotifications"
            size="small"
            text
            @click="clearNotifications"
          />
        </template>
        <p v-else class="px-5">
          {{ localizationObj.notifications.noNotifications }}
        </p>
      </div>
    </PvPopover>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import ButtonIcon from '../shared/components/ButtonIcon.vue';
import TimeAgo from '../../TimeAgo.vue';

export default {
  name: 'NotificationsCenter',
  components: { ButtonIcon, TimeAgo },
  computed: {
    ...mapState('templateStore', ['localizationObj']),
    ...mapGetters('templateStore', ['notificationsSorted', 'notificationToasts']),
    buttonTitle() {
      if (this.hasNotifications) {
        return this.localizationObj.notifications.seeNotifications;
      }
      return this.localizationObj.notifications.noNotifications;
    },
    hasNotifications() {
      return !!this.notificationsSorted.length;
    },
    badgeValue() {
      if (!this.hasNotifications) return '';
      if (this.notificationsSorted.length > 9) return '9+';
      return this.notificationsSorted.length;
    },
  },
  watch: {
    notificationToasts(toasts) {
      if (!toasts.length) return;
      const latestToast = toasts[toasts.length - 1];
      this.$toast.add({
        summary: latestToast.title,
        detail: latestToast.text,
        severity: latestToast.severity,
        life: 8000,
      });
    },
  },
  methods: {
    ...mapMutations('templateStore', [
      'templateRemoveNotification',
      'templateRemoveAllNotifications',
    ]),
    removeSingleNotification(timestamp) {
      this.templateRemoveNotification(timestamp);
      this.$nextTick(() => {
        this.$refs.menu.alignOverlay();
      });
    },
    toggleNotifications(event) {
      this.$refs.menu.toggle(event);
    },
    clearNotifications() {
      this.templateRemoveAllNotifications();
      this.$refs.menu.hide();
    },
    getSeverity(severity) {
      // severity is reused for toasts and their severities are slightly
      // so we do a bit of remapping
      switch (severity) {
        case 'warn':
          return 'warning';
        case 'error':
          return 'danger';
        default:
          return severity;
      }
    },
  },
};
</script>
