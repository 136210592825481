function getAccessToken() {
  return localStorage.getItem('accessToken');
}

function authHeader() {
  // return authorization header with jwt token
  const token = getAccessToken();
  if (token) {
    const payload = { Authorization: `JWT ${token}` };
    return payload;
  }
  return {};
}

export {
  getAccessToken,
  authHeader,
};
