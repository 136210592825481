import mitt from 'mitt';

const emitter = mitt();

/* adapted to support pre-vue3 syntax to lessen migration work */
export default {
  $emit: emitter.emit,
  $off: emitter.off,
  $on: emitter.on,
};
