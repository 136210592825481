<template>
  <span
    :title="title"
  >
    <slot>
      {{ deltaFormatted }}
    </slot>
  </span>
</template>

<script>
const map = [
  ['year', 31_536_000],
  ['month', 2_592_000],
  ['week', 604_800],
  ['day', 86_400],
  ['hour', 3_600],
  ['minute', 60],
  ['second', 1],
];

export default {
  name: 'TimeAgo',
  props: {
    iso: {
      type: [String, null],
      default: null,
    },
    epoch: {
      type: [Number, null],
      default: null,
    },
    date: {
      type: [Date, null],
      default: null,
    },
    formatType: {
      type: String,
      default: 'auto',
    },
    locale: {
      type: String,
      default: 'en-GB',
    },
  },
  data() {
    return {
      now: new Date(),
      timeout: null,
    };
  },
  computed: {
    formatter() {
      return new Intl.RelativeTimeFormat(this.locale, { numeric: 'auto' });
    },
    delta() {
      if (this.date) {
        return this.date - this.now;
      } if (this.iso) {
        return new Date(this.iso) - this.now;
      } if (this.epoch) {
        return new Date(this.epoch) - this.now;
      }
      console.error('Missing valid prop value');
      return 0;
    },
    deltaSeconds() {
      return this.roundTowardsZero(this.delta, 1000);
    },
    mapIndex() {
      const deltaAbsS = Math.abs(this.deltaSeconds);
      for (const [index, [, s]] of map.entries()) {
        if (deltaAbsS >= s) {
          return index;
        }
      }
      return 0;
    },
    deltaAbsolute() {
      return new Date(this.title).toLocaleString(this.locale);
    },
    deltaRelative() {
      const [unit, s] = map[this.mapIndex];
      // Round towards zero, otherwise we will go "59 seconds ago",
      // "1 min ago" to "2 min ago" in 2 seconds
      const time = this.roundTowardsZero(this.deltaSeconds, s);
      return this.formatter.format(time, unit);
    },
    deltaFormatted() {
      if (this.formatType === 'absolute') {
        return this.deltaAbsolute;
      }

      if (Math.abs(this.deltaSeconds) < 60 * 60) {
        return this.deltaRelative;
      }
      return this.deltaAbsolute;
    },
    nextUpdate() {
      const [, s] = map[this.mapIndex];
      let reminder = this.deltaSeconds % s;
      if (reminder < 0) {
        reminder += s;
      }
      return reminder + 1; // + 1 is needed if unit is second, and does not hurt other units
    },
    title() {
      return this.iso || this.epoch || this.date;
    },
  },
  mounted() {
    this.updateClock();
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  },
  methods: {
    roundTowardsZero(dividend, divisor) {
      if (dividend < 0) {
        return Math.ceil(dividend / divisor);
      }
      return Math.floor(dividend / divisor);
    },
    updateClock() {
      this.now = new Date();
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.updateClock, this.nextUpdate * 1000);
    },
  },
};
</script>
