<template>
  <div>
    <SwMessage
      v-if="showDeploymentAlert && loggedIn"
      :class="[{ 'mt-1': botAdminEnvironment !== 'production' }, 'block rounded-none']"
      severity="info"
      closable
    >
      Deployment: {{ botAdminName }}
    </SwMessage>
    <AppTemplate
      v-bind="{
        pages: filteredPages,
        showSidebar,
        userDetails,
        toolDetails: {
          name: 'BotAdmin',
          env: botAdminEnvironment,
        },
        loggedIn,
      }"
      notifications-center
    >
      <template #before-nav>
        <PvBadge
          v-if="hasDataOrigin && !sidebarCompact"
          :severity="dataOriginVariant"
          class="mx-3"
        >
          DATA ORIGIN: {{ chatsystemsState[0].status.settings.data_origin }}
        </PvBadge>
        <PvBadge
          v-if="!showDeploymentAlert && !sidebarCompact"
          class="mx-3 mt-2"
        >
          Deployment: {{ botAdminName }}
        </PvBadge>
      </template>
      <template #page-content>
        <RouterView v-slot="{ Component, route }">
          <transition name="fade" mode="out-in">
            <component :is="Component" :key="route.path" />
          </transition>
        </RouterView>
      </template>
    </AppTemplate>
  </div>
</template>

<script>
import AppTemplate from 'supwiz/components-vue3/template/AppTemplate.vue';
import {
  mapGetters, mapState,
} from 'vuex';
import { getBotAdminEnvironment, getBotAdminName } from '@/js/utils';

export default {
  name: 'App',
  components: { AppTemplate },
  data() {
    return {
      showDeploymentAlert: true,
    };
  },
  computed: {
    ...mapGetters('chatsystem', ['chatsystemsState']),
    ...mapState('sidebar', ['pageLoading', 'showSidebar']),
    ...mapGetters('sidebar', ['pages']),
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('auth', ['displayName']),
    ...mapState('templateStore', ['sidebarCompact']),
    hasDataOrigin() {
      return Object.values(this.chatsystemsState).length
       && !!this.chatsystemsState[0].status?.settings?.data_origin;
    },
    dataOriginVariant() {
      if (this.hasDataOrigin && this.chatsystemsState[0].status.settings.data_origin === 'DEV') {
        return 'danger';
      } if (this.chatsystemsState[0].status.settings.data_origin === 'TEST') {
        return 'warning';
      }
      return 'secondary';
    },
    chatType() {
      if (!Object.values(this.chatsystemsState).length) return undefined;
      return this.chatsystemsState[0].status?.settings?.type;
    },
    filteredPages() {
      if (!this.chatType) {
        return this.pages;
      }
      return this.pages.filter((p) => !p.platforms || p.platforms.includes(this.chatType));
    },
    userDetails() {
      return { displayName: this.displayName };
    },
    botAdminEnvironment() {
      return getBotAdminEnvironment();
    },
    botAdminName() {
      return getBotAdminName();
    },
  },
};
</script>
