<template>
  <slot
    v-bind="{
      buttonAttrs: {
        id: buttonId,
        ariaControls,
        ariaExpanded,
      },
      dialogAttrs: {
        visible: dialogIsVisible,
        id: dialogId,
      },
      toggleDialog,
    }"
  />
</template>

<script>
export default {
  name: 'DialogButtonWrapper',
  data() {
    return {
      dialogIsVisible: false,
    };
  },
  computed: {
    uid() {
      return this.$.uid;
    },
    ariaControls() {
      return this.dialogIsVisible ? this.dialogId : null;
    },
    ariaExpanded() {
      return this.dialogIsVisible;
    },
    buttonId() {
      return `dialog-button-${this.uid}`;
    },
    dialogId() {
      return `dialog-${this.uid}`;
    },
  },
  methods: {
    toggleDialog(val) {
      this.dialogIsVisible = !!val;
      if (!val) {
        this.$nextTick(() => {
          // return focus to trigger button
          document.getElementById(this.buttonId)?.focus();
        });
      }
    },
  },
}; </script>
